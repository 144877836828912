import { defineStore } from 'pinia'

export const storeRelatorio = defineStore('relatorio', {
  state: () => {
    return {
      nomeRelatorio: '',
      response: null,
      contentType: 'PDF',
      cpf: '',
    }
  },
})

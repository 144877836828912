<template>
  <div></div>
</template>

<script>
import * as XLSX from 'xlsx'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { storeRelatorio } from '@/stores/relatorio'

export default {
  name: 'ConciliacaoDeRetorno',

  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  mounted() {
    if ('EXCEL' === this.relatorioStore.contentType) {
      this.gerarExcel()
    } else {
      this.gerarPDF()
    }
  },

  methods: {
    gerarPDF() {
      const doc = new jsPDF()

      const formataPDF = (data) => {
        const margemSuperior = 10
        const margemInferior = 10
        const margemEsquerda = 4
        const margemDireita = 4
        let posY = margemSuperior

        const larguraPagina = doc.internal.pageSize.getWidth()

        if (!data || Object.keys(data).length === 0) {
          console.error(
            'Dados para o relatório não estão disponíveis ou estão vazios.',
          )
          return
        }

        Object.keys(data).forEach((entidade) => {
          let totalValorEntidade = 0
          let totalQuantidadeEntidades = 0
          doc.setFontSize(10)
          doc.setFont(undefined, 'bold')
          doc.setTextColor(255, 255, 255)
          doc.setFillColor(0, 0, 0)
          doc.rect(
            margemEsquerda,
            posY,
            larguraPagina - margemDireita - margemEsquerda,
            4,
            'F',
          )
          doc.text(`Entidade: ${entidade}`, 6, posY + 3)
          posY += 4

          Object.keys(data[entidade]).forEach((consignataria) => {
            let totalValorConsignataria = 0
            let totalQuantidadeConsignatarias = 0
            doc.setFillColor(246, 234, 203)
            doc.rect(4, posY, 202, 4, 'F')
            doc.setFontSize(8)
            doc.setTextColor(0, 0, 0)
            doc.text(`Razão Social: ${consignataria}`, 6, posY + 3)
            posY += 4

            Object.keys(data[entidade][consignataria]).forEach((rubrica) => {
              let totalValorRubrica = 0
              let totalQuantidadeRubrica =
                data[entidade][consignataria][rubrica].length
              doc.setFontSize(8)
              doc.setFillColor(196, 220, 224)
              doc.rect(4, posY, 202, 4, 'F')
              doc.setTextColor(0, 0, 0)
              doc.text(`Rubrica: ${rubrica}`, 6, posY + 3)
              posY += 4

              const rows = []
              data[entidade][consignataria][rubrica].forEach((consignacao) => {
                rows.push([
                  consignacao.matricula,
                  consignacao.nome,
                  consignacao.cpf,
                  consignacao.valor.toFixed(2),
                  consignacao.prazo,
                  consignacao.descontado,
                  consignacao.critica,
                ])
                totalValorRubrica += parseFloat(consignacao.valor)
              })

              doc.autoTable({
                margin: {
                  top: margemSuperior,
                  right: margemDireita,
                  bottom: margemInferior,
                  left: margemEsquerda,
                },
                columnStyles: {
                  0: { cellWidth: 16 },
                  1: { cellWidth: 60, overflow: 'hidden', halign: 'left' },
                  2: { cellWidth: 26, overflow: 'hidden' },
                  3: { cellWidth: 20, overflow: 'hidden', halign: 'left' },
                  4: { cellWidth: 16, overflow: 'hidden' },
                  7: { cellWidth: 16, overflow: 'hidden' },
                  8: { cellWidth: 60, overflow: 'hidden' },
                },
                startY: posY,
                head: [
                  [
                    'Matrícula',
                    'Nome',
                    'CPF',
                    'Valor',
                    'Prazo',
                    'Descontado?',
                    'Crítica',
                  ],
                ],
                body: rows,
                theme: 'plain',
                styles: {
                  fontSize: 7,
                  halign: 'center',
                  valign: 'middle',
                  fillColor: [255, 255, 255],
                  overflow: 'hidden',
                  cellPadding: 0.5,
                },
                headStyles: {
                  fillColor: [0, 121, 182],
                  textColor: [255, 255, 255],
                },
                alternateRowStyles: {
                  fillColor: [240, 240, 240],
                },
                didDrawPage: (data) => {
                  posY = data.cursor.y
                },
              })

              doc.setFillColor(196, 220, 224)
              doc.rect(4, posY, 202, 4, 'F')
              doc.setTextColor(0, 0, 0)
              doc.autoTable({
                startY: posY,
                margin: { top: 2, right: 4, bottom: 2, left: 4 },
                head: [],
                body: [
                  [
                    {
                      content: 'Sub-total rubrica: ' + totalQuantidadeRubrica,
                      colSpan: 3,
                      halign: 'center',
                    },
                    {
                      content:
                        'Sub-total Valor: R$ ' + totalValorRubrica.toFixed(2),
                      colSpan: 2,
                      halign: 'center',
                    },
                  ],
                  [
                    {
                      content: '--- Fim da Rubrica ---',
                      colSpan: 7,
                      halign: 'center',
                      fontStyle: 'bold',
                    },
                  ],
                ],
                styles: {
                  fontSize: 7,
                  cellPadding: 0.5,
                  halign: 'center',
                  valign: 'middle',
                },
                theme: 'plain',
                didDrawPage: (data) => {
                  posY = data.cursor.y
                },
              })

              totalValorConsignataria += totalValorRubrica
              totalQuantidadeConsignatarias += totalQuantidadeRubrica
            })

            doc.setFillColor(246, 234, 203)
            doc.rect(4, posY, 202, 4, 'F')
            doc.setTextColor(0, 0, 0)
            doc.autoTable({
              startY: posY,
              margin: { top: 2, right: 4, bottom: 2, left: 4 },
              head: [],
              body: [
                [
                  {
                    content:
                      'Total Consignatária: ' + totalQuantidadeConsignatarias,
                    colSpan: 3,
                    halign: 'center',
                  },
                  {
                    content:
                      'Total Valor: R$ ' + totalValorConsignataria.toFixed(2),
                    colSpan: 2,
                    halign: 'center',
                  },
                ],
                [
                  {
                    content: '--- Fim da Consignatária ---',
                    colSpan: 7,
                    halign: 'center',
                    fontStyle: 'bold',
                  },
                ],
              ],
              styles: {
                fontSize: 7,
                cellPadding: 0.5,
                halign: 'center',
                valign: 'middle',
              },
              theme: 'plain',
              didDrawPage: (data) => {
                posY = data.cursor.y
              },
            })

            totalValorEntidade += totalValorConsignataria
            totalQuantidadeEntidades += totalQuantidadeConsignatarias
          })

          doc.setFillColor(0, 0, 0)
          doc.rect(4, posY, 202, 4, 'F')
          doc.setTextColor(255, 255, 255)
          doc.autoTable({
            startY: posY,
            margin: { top: 2, right: 4, bottom: 2, left: 4 },
            head: [],
            body: [
              [
                {
                  content: 'Total Entidade: ' + totalQuantidadeEntidades,
                  colSpan: 3,
                  halign: 'center',
                  textColor: [255, 255, 255],
                },
                {
                  content: 'Total Valor: R$ ' + totalValorEntidade.toFixed(2),
                  colSpan: 2,
                  halign: 'center',
                  textColor: [255, 255, 255],
                },
              ],
            ],
            styles: {
              fontSize: 7,
              cellPadding: 0.5,
              halign: 'center',
              valign: 'middle',
              fontStyle: 'bold',
              textColor: [255, 255, 255],
            },
            theme: 'plain',
            didDrawPage: (data) => {
              posY = data.cursor.y
            },
          })

          doc.setTextColor(0, 0, 0)
          doc.autoTable({
            startY: posY,
            margin: { top: 2, right: 4, bottom: 2, left: 4 },
            head: [],
            body: [
              [
                {
                  content: '--- Fim da Entidade ---',
                  colSpan: 7,
                  halign: 'center',
                  fontStyle: 'bold',
                  textColor: [0, 0, 0],
                },
              ],
            ],
            styles: {
              fontSize: 7,
              cellPadding: 0.5,
              halign: 'center',
              valign: 'middle',
            },
            theme: 'plain',
            didDrawPage: (data) => {
              posY = data.cursor.y
            },
          })
        })
      }

      formataPDF(this.relatorioStore.response)
      doc.save('relatorio_conciliacao_de_retorno.pdf')
    },

    gerarExcel() {
      const wb = XLSX.utils.book_new()

      const formataExcel = (data) => {
        if (!data || Object.keys(data).length === 0) {
          console.error(
            'Dados para o relatório não estão disponíveis ou estão vazios.',
          )
          return
        }

        const ws_sumarizacao_data = []
        ws_sumarizacao_data.push([
          {
            v: 'Relatório de Sumarização',
            s: { font: { bold: true, sz: 14 } },
          },
        ])
        ws_sumarizacao_data.push(['', '', 'Quantidade:', 'Valor:'])

        let totalGeralQuantidade = 0
        let totalGeralValor = 0

        const totais = {}

        Object.keys(data).forEach((entidade) => {
          let totalQuantidadeEntidade = 0
          let totalValorEntidade = 0

          if (!totais[entidade]) totais[entidade] = {}

          Object.keys(data[entidade]).forEach((consignataria) => {
            let totalQuantidadeConsignataria = 0
            let totalValorConsignataria = 0

            if (!totais[entidade][consignataria])
              totais[entidade][consignataria] = {}

            Object.keys(data[entidade][consignataria]).forEach((rubrica) => {
              const rubricaData = data[entidade][consignataria][rubrica]
              let totalQuantidadeRubrica = 0
              let totalValorRubrica = 0

              if (Array.isArray(rubricaData)) {
                totalQuantidadeRubrica = rubricaData.length

                rubricaData.forEach((consignacao) => {
                  totalValorRubrica += parseFloat(consignacao.valor)
                })
              }

              totais[entidade][consignataria][rubrica] = {
                quantidade: totalQuantidadeRubrica,
                valor: totalValorRubrica,
              }

              totalQuantidadeConsignataria += totalQuantidadeRubrica
              totalValorConsignataria += totalValorRubrica
            })

            totais[entidade][consignataria].total = {
              quantidade: totalQuantidadeConsignataria,
              valor: totalValorConsignataria,
            }

            totalQuantidadeEntidade += totalQuantidadeConsignataria
            totalValorEntidade += totalValorConsignataria
          })

          totais[entidade].total = {
            quantidade: totalQuantidadeEntidade,
            valor: totalValorEntidade,
          }

          totalGeralQuantidade += totalQuantidadeEntidade
          totalGeralValor += totalValorEntidade
        })

        Object.keys(totais).forEach((entidade) => {
          const totalEntidade = totais[entidade].total

          ws_sumarizacao_data.push([
            { v: `${entidade}`, s: { font: { bold: true, sz: 12 } } },
            '',
            { v: `${totalEntidade.quantidade}`, s: { font: { sz: 12 } } },
            {
              v: `R$ ${totalEntidade.valor.toFixed(2)}`,
              s: { font: { sz: 12 } },
            },
          ])

          Object.keys(totais[entidade]).forEach((consignataria) => {
            if (consignataria === 'total') return

            const totalConsignataria = totais[entidade][consignataria].total

            ws_sumarizacao_data.push([
              {
                v: `    ${consignataria}`,
                s: { font: { bold: true, sz: 11 } },
              },
              '',
              {
                v: `${totalConsignataria.quantidade}`,
                s: { font: { sz: 11 } },
              },
              {
                v: `R$ ${totalConsignataria.valor.toFixed(2)}`,
                s: { font: { sz: 11 } },
              },
            ])

            Object.keys(totais[entidade][consignataria]).forEach((rubrica) => {
              if (rubrica === 'total') return // Ignora o total da consignatária

              const totalRubrica = totais[entidade][consignataria][rubrica]

              ws_sumarizacao_data.push([
                { v: `        ${rubrica}`, s: { font: { sz: 10 } } },
                '',
                { v: `${totalRubrica.quantidade}`, s: { font: { sz: 10 } } },
                {
                  v: `R$ ${totalRubrica.valor.toFixed(2)}`,
                  s: { font: { sz: 10 } },
                },
              ])
            })
          })

          ws_sumarizacao_data.push([])
        })

        ws_sumarizacao_data.push([
          { v: 'Total Geral', s: { font: { bold: true, sz: 14 } } },
          '',
          {
            v: `${totalGeralQuantidade}`,
            s: { font: { bold: true, sz: 12 } },
          },
          {
            v: `R$ ${totalGeralValor.toFixed(2)}`,
            s: { font: { bold: true, sz: 12 } },
          },
        ])

        const ws_sumarizacao = XLSX.utils.aoa_to_sheet(ws_sumarizacao_data)

        ws_sumarizacao['!cols'] = [
          { wch: 60 },
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
        ]

        XLSX.utils.book_append_sheet(wb, ws_sumarizacao, 'Sumarização')

        const ws_data = []

        ws_data.push([
          {
            v: 'Conciliação de Movimento',
            s: { font: { bold: true, sz: 12 } },
          },
        ])
        ws_data.push([])

        const entidades = Object.keys(data)
        entidades.forEach((entidade, entidadeIndex) => {
          let totalValorEntidade = 0
          let totalQuantidadeEntidades = 0

          ws_data.push([
            {
              v: `Entidade: ${entidade}`,
              s: { font: { bold: true, sz: 11 } },
            },
          ])

          const consignatarias = Object.keys(data[entidade])
          consignatarias.forEach((consignataria, consignatariaIndex) => {
            let totalValorConsignataria = 0
            let totalQuantidadeConsignatarias = 0

            ws_data.push([
              {
                v: `Razão Social: ${consignataria}`,
                s: { font: { bold: true, sz: 11 } },
              },
            ])

            const rubricas = Object.keys(data[entidade][consignataria])
            rubricas.forEach((rubrica, rubricaIndex) => {
              let totalValorRubrica = 0
              let totalQuantidadeRubrica = 0

              const rubricaData = data[entidade][consignataria][rubrica]

              ws_data.push([
                {
                  v: `Rubrica: ${rubrica}`,
                  s: { font: { bold: true, sz: 11 } },
                },
              ])

              if (Array.isArray(rubricaData)) {
                totalQuantidadeRubrica = rubricaData.length

                ws_data.push([
                  'Matrícula',
                  'Nome',
                  'CPF',
                  'Valor',
                  'Prazo',
                  'Descontado',
                  'Crítica',
                ])

                rubricaData.forEach((consignacao) => {
                  ws_data.push([
                    consignacao.matricula,
                    consignacao.nome,
                    consignacao.cpf,
                    consignacao.valor.toFixed(2),
                    consignacao.prazo,
                    consignacao.descontado,
                    consignacao.critica,
                  ])
                  totalValorRubrica += parseFloat(consignacao.valor)
                })
              } else {
                console.error(`Rubrica ${rubrica} não é um array.`)
                return
              }

              ws_data.push([
                {
                  v: `Sub-total rubrica: ${totalQuantidadeRubrica}`,
                  s: { font: { bold: true } },
                },
                '',
                '',
                {
                  v: `Sub-total Valor: R$ ${totalValorRubrica.toFixed(2)}`,
                  s: { font: { bold: true } },
                },
              ])

              totalValorConsignataria += totalValorRubrica
              totalQuantidadeConsignatarias += totalQuantidadeRubrica

              if (rubricaIndex < rubricas.length - 1) {
                ws_data.push([])
              }
            })

            ws_data.push([
              {
                v: `Total Razão Social: ${totalQuantidadeConsignatarias}`,
                s: { font: { bold: true } },
              },
              '',
              '',
              {
                v: `Total Valor: R$ ${totalValorConsignataria.toFixed(2)}`,
                s: { font: { bold: true } },
              },
            ])

            totalValorEntidade += totalValorConsignataria
            totalQuantidadeEntidades += totalQuantidadeConsignatarias

            if (consignatariaIndex < consignatarias.length - 1) {
              ws_data.push([])
            }
          })

          ws_data.push([
            {
              v: `Total Entidade: ${totalQuantidadeEntidades}`,
              s: { font: { bold: true } },
            },
            '',
            '',
            {
              v: `Total Valor: R$ ${totalValorEntidade.toFixed(2)}`,
              s: { font: { bold: true } },
            },
          ])

          if (entidadeIndex < entidades.length - 1) {
            ws_data.push([])
          }
        })

        const ws = XLSX.utils.aoa_to_sheet(ws_data)
        ws['!cols'] = [
          { wch: 30 },
          { wch: 40 },
          { wch: 15 },
          { wch: 25 },
          { wch: 10 },
          { wch: 10 },
          { wch: 30 },
        ]

        XLSX.utils.book_append_sheet(wb, ws, 'Conciliação de Movimento')

        XLSX.writeFile(wb, 'Relatório_ConciliaçãoMovimento.xlsx')
      }

      formataExcel(this.relatorioStore.response)
    },
  },
}
</script>

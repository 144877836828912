<template>
  <span>
    <h3 id="titulo" class="text-center">Extrato de Consignações</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Cadastro</th>
            <th>Consignatária</th>
            <th>Rubrica</th>
            <th>Contrato</th>
            <th>Prazo Total</th>
            <th>Prazo Restante</th>
            <th>Situação</th>
            <th class="text-right">Valor</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in extratoConsignacoesList">
            <tr v-for="data in item.consignacoes" :key="data.id">
              <td>{{ data.auditMetadata.cadastradoEm | formatarData }}</td>
              <td>{{ truncateText(item.consignatariaDto.nomeFantasia) }}</td>
              <td>{{ data.rubrica.rubrica }}</td>
              <td>{{ data.numeroContrato }}</td>
              <td>{{ data.prazoTotal }}</td>
              <td>{{ data.prazoRestante }}</td>
              <td>{{ formataDescricaoEnum(data.statusConsignacao) }}</td>
              <td class="text-right">
                <span v-if="relatorioStore.contentType == 'PDF'">
                  {{ data.valorParcela | formatarValor }}
                </span>
                <span v-else>
                  {{ data.valorParcela | formatarValorSemCifrao }}
                </span>
              </td>
            </tr>
            <tr id="subTotal" :key="index">
              <td colspan="7" class="text-right">SubTotal</td>
              <td class="formatarValor">
                {{ item.subTotal | formatarValor }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7" class="text-right">Total</td>
            <td class="formatarValor">{{ total | formatarValor }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import * as XLSX from 'xlsx'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { storeRelatorio } from '@/stores/relatorio'
import ServidorService from '@/service/ServidorService.js'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      extratoConsignacoesList: [],
      total: 0,
      isMounted: false,
      dadosDoServidor: {},
    }
  },

  mounted() {
    this.isMounted = true
  },

  async created() {
    this.extratoConsignacoesList = this.calcularSubTotal(
      this.relatorioStore.response[0].extratoConsignacaoDto,
    )

    this.servidorService = new ServidorService(this.$http)

    this.servidorService
      .buscaServidor(this.relatorioStore.cpf)
      .then((res) => {
        this.dadosDoServidor = res[0]
        if (this.isMounted) {
          if ('EXCEL' === this.relatorioStore.contentType) {
            this.gerarExcel()
          } else {
            this.gerarPDF()
          }
        } else {
          throw new Error(
            'Não é possível montar o relatório pois o DOM não está pronto, isMounted é false',
          )
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar servidor: ', error)
      })
  },

  methods: {
    gerarPDF() {
      const doc = new jsPDF('l', 'pt', 'a4')

      doc.setFontSize(8)
      doc.setFont('helvetica', 'bold')
      const cabecalho = document.getElementById('cabecalho').innerHTML
      doc.text(15, 30, cabecalho, {
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      doc.setFont('helvetica', 'bold')
      const cabecalho2 = document.getElementById('cabecalho2').innerHTML
      const textoComQuebraDelinha =
        '\n' +
        this.dadosDoServidor.nome +
        ' - ' +
        this.dadosDoServidor.cpf +
        cabecalho2
      doc.text(15, 36, textoComQuebraDelinha, {
        margin: { top: 8, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      const titulo = document.getElementById('titulo').innerHTML
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(14)
      doc.text(doc.internal.pageSize.getWidth() / 2, 70, titulo, {
        align: 'center',
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      doc.autoTable({
        html: '#tabela',
        startY: 100,
        useCss: true,
        showFoot: 'lastPage',
        didDrawPage: function (data) {
          let str = 'Página ' + doc.internal.getNumberOfPages()
          doc.setFontSize(10)
          let pageSize = doc.internal.pageSize
          let pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
      })

      const filename = this.relatorioStore.nomeRelatorio + '.pdf'
      doc.save(filename)
    },

    gerarExcel() {
      const table = document.getElementById('tabela')
      const wb = XLSX.utils.table_to_book(table)

      const ws = wb.Sheets[wb.SheetNames[0]]

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      const cabecalho = document.getElementById('cabecalho').innerHTML
      const cabecalho2 = `SERVIDOR:  ${this.dadosDoServidor.nome}  -  CPF: ${this.dadosDoServidor.cpf}`

      const newData = []

      newData.push([
        'Relatório Extrato de Consignação',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ])
      newData.push([cabecalho, '', '', '', '', '', '', ''])
      newData.push([cabecalho2, '', '', '', '', '', '', ''])
      newData.push(['', '', '', '', '', '', '', ''])
      newData.push(...data)

      const newWs = XLSX.utils.aoa_to_sheet(newData)

      newWs['A1'].s = {
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      }

      newWs['!cols'] = [
        { wpx: 64 },
        { wpx: 120 },
        { wpx: 40 },
        { wpx: 100 },
        { wpx: 40 },
        { wpx: 60 },
        { wpx: 120 },
        { wpx: 80 },
      ]

      wb.SheetNames = []
      wb.Sheets = {}

      XLSX.utils.book_append_sheet(wb, newWs, 'Relatório')

      XLSX.writeFile(wb, 'Relatório_ExtratoConsignacao.xlsx')
    },

    calcularSubTotal(data) {
      data.forEach((item) => {
        item.subTotal = this.calcularSubTotalPorExtrato(item.consignacoes)
      })
      return data
    },

    calcularSubTotalPorExtrato(consignacoes) {
      let subTotal = 0

      consignacoes.forEach((consignacao) => {
        subTotal += consignacao.valorParcela
      })

      this.total += subTotal

      return subTotal
    },

    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    },

    formataDescricaoEnum(texto) {
      const statusConsignacao = {
        NAO_DESCONTADA: 'NÃO DESCONTADA',
        AGUARDANDO_SUPERVIDOR: 'AGUARDANDO SUPERVIDOR',
        NAO_APROVADA_SUPERVISOR: 'NÃO APROVADA SUPERVISOR',
        DESCONTO_A_MENOR: 'DESCONTO A MENOR',
        SOLICITADO_CANCELAMENTO: 'SOLICITADO CANCELAMENTO',
        FORA_MARGEM: 'FORA DE MARGEM',
        SOLICITADA_SUSPENSAO: 'SOLICITADA SUSPENSAO',
        MOVIMENTO_PARCIAL: 'MOVIMENTO PARCIAL',
      }

      // eslint-disable-next-line no-prototype-builtins
      if (statusConsignacao.hasOwnProperty(texto)) {
        return statusConsignacao[texto]
      } else {
        return texto
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 11px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

#tabela td {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
}

#tabela tfoot {
  background-color: #333;
  color: #fff;
  text-align: center;
  border: 1px solid #333;
}

#subTotal {
  background-color: #f2f2f2;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>

<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Taxa Assistencial</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Resp.</th>
            <th>Consignatário</th>
            <th>Atividade</th>
            <th class="pl-7">Valor</th>
            <th class="pl-7 text-right">Quantidade Desconto</th>
            <th class="pl-8 text-right">Valor Desconto</th>
            <th class="pl-8 text-right">Total Folha</th>
            <th class="pl-8 text-right">Total Tarifas</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, index) in relatorioStore.response"
            :key="data.responsavel + '-' + index">
            <td>{{ data.responsavel }}</td>
            <td>{{ data.consignatario }}</td>
            <td>{{ data.atividade }}</td>
            <td class="formatarValor">
              {{ data.valorPadrao | formatarValor }}
            </td>
            <td class="text-right">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.quantidadeDescontos | formatarValor }}
              </span>
              <span v-else>
                {{ data.quantidadeDescontos | formatarValorSemCifrao }}
              </span>
            </td>

            <td class="text-right">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.valorDescontos | formatarValor }}
              </span>
              <span v-else>
                {{ data.valorDescontos | formatarValorSemCifrao }}
              </span>
            </td>

            <td class="text-right">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.totalFolha | formatarValor }}
              </span>
              <span v-else>
                {{ data.totalFolha | formatarValorSemCifrao }}
              </span>
            </td>

            <td class="text-right">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.totalTarifas | formatarValor }}
              </span>
              <span v-else>
                {{ data.totalTarifas | formatarValorSemCifrao }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-right">Total</td>
            <td class="text-right">
              {{ totais.quantidadeDescontos | formatarValor }}
            </td>
            <td class="text-right">
              {{ totais.valorDescontos | formatarValor }}
            </td>
            <td class="text-right">
              {{ totais.totalFolha | formatarValor }}
            </td>
            <td class="text-right">
              {{ totais.totalTarifas | formatarValor }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      totais: {},
    }
  },

  mounted() {
    if ('EXCEL' === this.relatorioStore.contentType) {
      this.$emit('gerarExcel')
    } else {
      this.$emit('gerarPDF')
    }
  },

  created() {
    this.adicionarTotal()
    this.removerTotalGeralDasLista()
  },

  methods: {
    adicionarTotal() {
      this.totais =
        this.relatorioStore.response[this.relatorioStore.response.length - 1]
    },

    removerTotalGeralDasLista() {
      this.relatorioStore.response.pop()
    },

    calcularQuantidadeDescontos(data) {
      this.totalQuantidadeDescontos += data.quantidadeDescontos
    },

    calcularValorDescontos(data) {
      this.totalValorDescontos += data.valorDescontos
    },

    calcularTotalFolha(data) {
      this.totalTotalFolha += data.totalFolha
    },

    calcularTotalTarifas(valor) {
      this.totalTotalTarifas += valor.totalTarifas
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  padding-right: 20px !important;
  // text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  font-size: 12px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 8px;
}

#tabela td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

#tabela tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border-collapse: collapse;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 8px;
  }

  #tabela td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>

<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Consignações</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Matrícula</th>
            <th>CPF</th>
            <th>Servidor</th>
            <th>Mês/Ano</th>
            <th>Data do Cadastro</th>
            <th>Rubrica</th>
            <th>Entidade</th>
            <th>Contrato</th>
            <th>Prazo</th>
            <th>Situação</th>
            <th class="pl-5 text-right">Valor da Parcela</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="consignacao in relatorioStore.response"
            :key="consignacao.id">
            <td>{{ consignacao.matricula.matricula }}</td>
            <td>{{ consignacao.matricula.servidor.cpf }}</td>
            <td>{{ consignacao.matricula.servidor.nome }}</td>
            <td>
              {{ consignacao.mesReferencia }}/{{ consignacao.anoReferencia }}
            </td>
            <td>
              {{ consignacao.auditMetadata.cadastradoEm | formatarData }}
            </td>
            <td>
              {{ consignacao.rubrica.rubrica }} -
              {{ consignacao.rubrica.nome }}
            </td>
            <td>{{ consignacao.rubrica.entidade.nome }}</td>
            <td>{{ consignacao.numeroContrato }}</td>
            <td>
              {{ consignacao.prazoRestante }}/{{ consignacao.prazoTotal }}
            </td>
            <td>{{ consignacao.statusConsignacaoDto.nome }}</td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ consignacao.valorParcela | formatarValor }}
              </span>
              <span v-else>
                {{ consignacao.valorParcela | formatarValorSemCifrao }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="9" class="text-right">Total</td>
            <td colspan="3" class="formatarValor">
              {{ total | formatarValor }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  name: 'TempalteRelatorioConsignacao',

  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      total: 0,
    }
  },

  mounted() {
    if ('EXCEL' === this.relatorioStore.contentType) {
      this.$emit('gerarExcel')
    } else {
      this.$emit('gerarPDF')
    }
  },

  created() {
    this.calcularTotal()
  },

  methods: {
    calcularTotal() {
      this.relatorioStore.response.forEach((consignacao) => {
        this.total += consignacao.valorParcela
      })
    },

    formatarValor(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
}

#tabela th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

#tabela td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

#tabela tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
